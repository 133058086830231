/**
 * Created by andrew.stalker on 18/09/2019. Sounds wrappers and settings.
 */

/* globals getConfig, isAndroid, console, window */
var soundSettings = {
	alertSoundTypes: Object.freeze({info: "info", success: "success", error: "error"}),
	confirmScanSound: (localStorage.getVariable("settings/confirmScanSound")=== "true")
};
// Global variables to store the sound data
var successSoundData = null;
var errorSoundData = null;
var infoSoundData = null;
// Assign the indexdb objects
window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
// Only try to use indexdb if its available. only try this on an android device.
if (window.indexedDB && isAndroid) {
	// Try to open the db
	var dbQueryRequest = window.indexedDB.open("soundsDatabase", 2.0);
	/**
	 * Handle if errors occur when trying to open the indexDB
	 * @param {*} e 
	 */
	dbQueryRequest.onerror = function(e) {
		alert('Failed to cache sound files. Please clear the cache and refresh the page');
		console.error('Unable to open database:' + e);
	};
	/**
	 * If the database opens correctly look for the sound files table
	 * @param {*} e 
	 */
	dbQueryRequest.onsuccess = function(e) {
		var db = e.target.result;
		// Open the transaction
		try {
			var transaction = db.transaction(["soundFiles"], "readwrite");
			// As long as the transaction is valid
			if (transaction) {
				// Success sound
				var successSoundRequest = transaction.objectStore("soundFiles").get("successSound");
				successSoundRequest.onsuccess = function (event) {
					var successSoundDataResult = event.target.result;
					// if data is stored then use it
					if (successSoundDataResult) {
						successSoundData = successSoundDataResult;
					} else {
						handleSoundDataNotLoaded("successSound", "/sounds/success.mp3", db);
					}
				};
				// Error sound
				var errorSoundRequest = transaction.objectStore("soundFiles").get("errorSound");
				errorSoundRequest.onsuccess = function (event) {
					var errorSoundDataResult = event.target.result;
					// if data is stored then use it
					if (errorSoundDataResult) {
						errorSoundData = errorSoundDataResult;
					} else {
						handleSoundDataNotLoaded("errorSound", "/sounds/error.mp3", db)
					}
				};
				// Info sound
				var infoSoundRequest = transaction.objectStore("soundFiles").get("infoSound");
				infoSoundRequest.onsuccess = function (event) {
					var infoSoundDataResult = event.target.result;
					// if data is stored then use it
					if (infoSoundDataResult) {
						infoSoundData = infoSoundDataResult;
					} else {
						handleSoundDataNotLoaded("infoSound", "/sounds/info.mp3", db)
					}
				};
			}
		} catch (error) {
			alert('Failed to load sound files. Please clear the cache and refresh the page');
			console.log('Unable to load sounds: ' + error);
		}
	};
	/**
	 * On initial setup this is rqeuired to build the required table
	 * @param {*} e 
	 */
	dbQueryRequest.onupgradeneeded = function(e) {
		var db = e.target.result;
		db.createObjectStore('soundFiles');
	};

	/**
	 * If no sound is available in the db try to download it
	 * @param {*} objectName 
	 * @param {*} url 
	 * @param {*} db 
	 */
	function handleSoundDataNotLoaded(objectName, url, db) {
		// Load the sound data into a request
		var request = new XMLHttpRequest();
		request.open('GET', url, true);
		request.responseType = 'blob';
		request.onload = function() {
			// Convert the raw data to a data url
			var reader = new FileReader();
			reader.readAsDataURL(request.response);
			reader.onload =  function(e){
				// Extract the data
				var audioData = e.target.result;
				// Open a new db transaction
				var transaction = db.transaction(["soundFiles"], "readwrite");
				// Ensure transaction is valid
				if (transaction) {
					// Store the sound in the db
					transaction.objectStore("soundFiles").put(audioData, objectName);
					// Update the global variable
					if (objectName === "successSound") {
						successSoundData = audioData;
					} else if (objectName === "errorSound") {
						errorSoundData = audioData;
					} else if (objectName === "infoSound") {
						infoSoundData = audioData;
					}
				}
			};
		};
		request.send();
	}
}

/**
 * Helper method to try to play Android sounds to ensure the audio tag is 
 * unlocked and able to play them
 */
function unlockAudio() {
	"use strict";
	if (isAndroid) {
		var nativeAudioPlayer = document.getElementById('alertAudio');
		nativeAudioPlayer.volume = 0.0;
		playInfoSound();
		nativeAudioPlayer.volume = 1.0;
	}
}

/**
 * @description Plays the correct sound based on the alertType to be shown.
 * @param {string} alertType
 */
function playAlertSound(alertType){
	"use strict";
	switch(alertType) {
		case soundSettings.alertSoundTypes.info:
			playInfoSound(false);
			break;
		case soundSettings.alertSoundTypes.success:
			playSuccessSound(false);
			break;
		default :
			playErrorSound(false);
			break;
	}
}

function playSuccessSound(checkConfirmation) {
	"use strict";
		if (checkConfirmation && !soundSettings.confirmScanSound) {
			return; // Method was called to check the setting, and setting is false. Do not play.
		}
		//Try and get the wavPlayer from the plugin for Windows. If we can't then we are probably on a device that plays audio.
		var wavPlayer = document.getElementById("WavPlayerX");
		if (typeof wavPlayer.Play === 'function') {
			getConfig( function(localConfig) {
				wavPlayer.Play(localConfig.installationPath + "\\sounds\\success.wav"); //Now only plays if Play is a function - so automatically works on scanner if plugin enabled.
			});
		} else if (isAndroid) {
			var nativeAudioPlayer = document.getElementById('alertAudio');
			if (!successSoundData) {
				nativeAudioPlayer.src = "/sounds/success.mp3";
			} else {
				nativeAudioPlayer.src = successSoundData;
				nativeAudioPlayer.load();
			}
			nativeAudioPlayer.play();
		} else {
			console.log("Error detecting a suitable audio player");
		}
}

function playInfoSound(checkConfirmation) {
	"use strict";
		if (checkConfirmation && !soundSettings.confirmScanSound) {
			return; // Method was called to check the setting, and setting is false. Do not play.
		}
		//Try and get the wavPlayer from the plugin for Windows. If we can't then we are probably on a device that plays audio.
		var wavPlayer = document.getElementById("WavPlayerX");
		if (typeof wavPlayer.Play === 'function') {
			getConfig(function(localConfig) {
				wavPlayer.Play(localConfig.installationPath + "\\sounds\\info.wav"); //Now only plays if Play is a function - so automatically works on scanner if plugin enabled.
			  });
		} else if (isAndroid) {
			var nativeAudioPlayer = document.getElementById('alertAudio');
			if (!infoSoundData) {
				nativeAudioPlayer.src = "/sounds/info.mp3";
			} else {
				nativeAudioPlayer.src = infoSoundData;
				nativeAudioPlayer.load();
			}
			nativeAudioPlayer.play();
		} else {
			console.log("Error detecting a suitable audio player");
		}
}

function playErrorSound(checkConfirmation) {
	"use strict";
		if (checkConfirmation && !soundSettings.confirmScanSound) {
			return; // Method was called to check the setting, and setting is false. Do not play.
		}
		//Try and get the wavPlayer from the plugin for Windows. If we can't then we are probably on a device that plays audio.
		var wavPlayer = document.getElementById("WavPlayerX");
		if (typeof wavPlayer.Play === 'function') {
			getConfig(function(localConfig) {
				wavPlayer.Play(localConfig.installationPath + "\\sounds\\error.wav"); //Now only plays if Play is a function - so automatically works on scanner if plugin enabled.
			});
		} else if (isAndroid) {
			var nativeAudioPlayer = document.getElementById('alertAudio');
			if (!errorSoundData) {
				nativeAudioPlayer.src = "/sounds/error.mp3";
			} else {
				nativeAudioPlayer.src = errorSoundData;
				nativeAudioPlayer.load();
			}
			nativeAudioPlayer.play();
		} else {
			console.log("Error detecting a suitable audio player");
		}
}
